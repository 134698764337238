import { IAccount } from "./Account"
import { IPlayerSettings } from "./IPlayerSettings";


export interface IPlayerMinimal {
    uuid:string    
    avatarUri:string|null
    rating:number|null
    name:string                                  
}

export interface IPlayer extends IPlayerMinimal {   
    uuid:string    
    avatarUri:string|null
    //countryCode:    
    rating:number|null
    /** @deprecate isDemo */    
    isDemo:boolean     
    is_robot:boolean        
    name:string    
    description:string    
    type_id:number
    active_account_uuid:string    
    created_timestamp:number    
    created:string
    account?:IAccount
    settings:IPlayerSettings
}

export interface IPlayerWithAccount extends IPlayer {   
    account:IAccount    
}


export enum EPlayerType {
    TYPE_ID_NONE    = 0,
    TYPE_ID_PERSON  = 1,
    TYPE_ID_BOT     = 2,
    TYPE_ID_TEST    = 10,
    TYPE_ID_DEMO    = 11,
    TYPE_ID_EXTERNAL = 30,
}

export declare type PlayerUuid = string;



export default class Player implements IPlayer {                
    uuid!:PlayerUuid        
    avatarUri!:string|null
    avatarCode!:number 
    //countryCode:    
    rating!:number|null
    /** @deprecate isDemo */    
    isDemo!:boolean     
    is_robot!:boolean    
    name!:string    
    description!:string    
    type_id!:EPlayerType        
    active_account_uuid!:string    
    created_timestamp!:number    
    created!:string
    settings!:IPlayerSettings

    constructor(data:any) {          
        Object.assign(this, data);
    }
    
}



// avatarUri: "https://wbgdev.ngmcasino.com/nard_server/statics/pictures/avatars/avatar_15.png"
// countryCode: null
// rating: 1469
// isDemo: false
// uuid: "686cb095-1d67-4c25-aae3-5ec99e772d93"
// name: "testPlayer"
// description: "testPlayer"
// type_id: 1
// active_account_uuid: "97c8a2a0-aef5-4fb0-ac62-caeee2e6abac"
// created_timestamp: 1586426420
// created: "2020-04-09 13:00:20+03"
// data: null


// settings:
// account_chip: {uuid: "", currency: {…}}
// account_desk: {uuid: "", currency: {…}}
// avatar_type: 999
// color: "any"
// country_code: "ru"
// feature_auto_bearing_off: "auto"
// feature_auto_dice: "true"
// feature_auto_turn: "wait"
// feature_double_click_move_type: "big_first"
// flag_color: "red"
// game_create: {skill_level: "novice", speed_level: "normal", play_type: 0, game_type: 1, time_for_one_step: 180, …}
// lang: "en"
// m_player: null
// other: null
// sound_enable: true
// __proto__: Object



