function isPromise(object:any){    
    return Promise.resolve(object) == object;    
}

// do and dont's  https://www.typescriptlang.org/docs/handbook/declaration-files/do-s-and-don-ts.html

export default abstract class ModelCollection<T> {

    public ttl:{[key: string]: NodeJS.Timeout}
    public models:{[key: string]: T}
    public keyName:string

    constructor() {
        this.ttl = {}
        this.models = {}
        this.keyName = "uuid";
    }

    itemUpdateArray(modelArray:Array<any>):boolean {
        modelArray.forEach((modelData:any) => this.itemUpdate(modelData));        
        return modelArray.length > 0
    }
    
    itemUpdate(modelData:any, ttl:number = 0):void {
        let key = modelData[this.keyName];
        let itemOld = this.get(key);
        let itemNew:T = this.itemCreate(modelData);
        let ttlOld = this.ttl[key];
        if(ttlOld)
            clearTimeout(ttlOld);
                                
        if(!this.itemDead(itemNew)) {   
            this.models[key] = itemNew;
            if(ttl) {
                this.ttl[key] = setTimeout(()=> {
                    this.delete(key);
                }, ttl);
            }
            return;
        }                        
        if(itemOld) // new item dead
            this.delete(key);            
    }
    
    abstract itemCreate(data:object):T;            

    itemDead(item:T):boolean {
        return false;
    }

    get(key:string):T | undefined {        
        return this.models[key];
    }

    size() { return Object.keys(this.models).length;}
    get length() {return this.size()}

    delete(key:string):void {
        delete this.models[key];
        delete this.ttl[key];
    }    

    clear() {
        this.models = {}
    }

    async forEachAsync(cb:(element:T, key:string, object:object) => void) {
        let object = this.models;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const element = object[key];                
                let result = cb(element, key, object);
                if(isPromise(result))
                    await result;
            }
        }
    }
}