import Player, { IPlayer, IPlayerMinimal } from "./Player"
import Currency, { ICurrency } from "./Currency"
import { ITournamentInRules } from "./Tournament"

export enum EGameStatus {
     //from GameConnector
     NONE               = 0,
     SEARCH_OPPONENT    = 10,
     WAIT_PLAYERS       = 20,
     IN_PLAY            = 30,
     FINISHED           = 40,
     CANCELED           = 50,
}

export enum EGameType {
    TRAIN        = 0,
    CUSTOM_DUEL  = 1,
    CUSTOM_MATCH = 2,
    TOURNAMENT   = 3,
}

export enum EGameKind {
    NOT_SET = 0,
    LONG    = 1,
    SHORT   = 2,
}

export const GAME_MAX_SCORE_VARIANTS = [1,3,5,10,15]
export const GAME_TRAIN_TIME_STEP_VARIANTS = [30,60,300,600,900]
export const GAME_TIME_STEP_VARIANTS = [30,60,90,120,150]

export enum EGameSpeed {
    EXPRESS = "express",
    FAST = "fast",
    NORMAL = "normal" ,
    SLOW = "slow",
    INFINITE = "infinite",
    LONG = "long",
    INVALID = "invalid",
}



export interface IGameRules {    
    type: string
    timeLimit: {
        step:number      // time to step
        diceShow:number  // time to show dices when negotiate
        waitReady:number // time to wait opponent ready
    }
    paritet:{
        enabled:boolean
        scoreEach:number
        scoreScale:number
    },
    playerOrder: {
        type:string // unknown playerA playerB                                    
    }
    firstStepDice: number,
    settings: any,
    doubleCube: {
        enabled:boolean
        initialOwner: string // 'none'
        autoDouble: {
            enabled: boolean
            limit: number
        }
        biver:boolean
        rakun:boolean
    }
    modifications:{
        mars:boolean
        koks:boolean
        jakobi:boolean
        crawford:boolean
    }
    tournament?:ITournamentInRules    
}

interface IGameStatisticPlayer  {
    dice:{
        count: number
        doublesCount: number[] //size 6
        lost: number
        lostCount: number
        sum: number
        used: number
    },
    moveNumber: number
    timeTotal: number
}

interface IGameStatistic  {
    playerA:IGameStatisticPlayer
    playerB:IGameStatisticPlayer
}

export declare type GameUuid = string;

export interface IGame {    
    uuid:GameUuid    
    player_a_score:number    
    player_b_score:number
    player_a_uuid:string
    player_b_uuid:string    
    bet_price:number    
    win_total:number|null    
    caption:string    
    max_score:number    
    status: EGameStatus                
    type_id:      EGameType     
    game_type_id: EGameKind
    rules: IGameRules   

    speedStep:EGameSpeed
    // isNeedPassword:boolean    
        
    timeout:string,
    timeout_timestamp:number

    created:string
    created_timestamp:number

    modified:string
    modified_timestamp:number

    started?:string
    started_timestamp?:number

    finished?:string
    finished_timestamp?:number

    statistic?:IGameStatistic
    
    tour?:any

    scoreString?: string
}

export interface IGameWithPlayersMinimalAndCurrency extends IGame{
    playerA: IPlayerMinimal
    playerB: IPlayerMinimal
    currency: ICurrency
}

export interface IGameWithPlayersAndCurrency extends IGame{
    playerA: IPlayer
    playerB: IPlayer
    currency: ICurrency
}

export default class Game implements IGame {     
    uuid!:string;     
    player_a_score!:number;        
    player_b_score!:number
        
    player_a_uuid!:string    
    player_b_uuid!:string    
    bet_price!:number    
    win_total!:number|null    
    caption!:string    
    max_score!:number    
    status!: EGameStatus    
    //isInPlay:boolean  
    //isFinished:boolean    
    //isNeedPassword:boolean    
    type_id!:      EGameType     
    game_type_id!: EGameKind        
    rules!: IGameRules        
    speedStep!:EGameSpeed
    // isNeedPassword:boolean                
    timeout!:string    
    timeout_timestamp!:number    
    created!:string    
    created_timestamp!:number    
    modified!:string    
    modified_timestamp!:number
    
    started?:string    
    started_timestamp?:number    
    finished?:string    
    finished_timestamp?:number    
    statistic?:IGameStatistic  
    
    tour?:any

    
    private _playerA?: Player 
    private _playerB?: Player 
    private _currency?:Currency  

    
    constructor(data:any) {
        Object.assign(this, data);         
    }

    get currency()      {return this._currency}
    set currency(value) { this._currency = value ? new Currency(value): undefined;}
    get playerA()       {return this._playerA}
    set playerA(value)  { this._playerA = value ? new Player(value): undefined;}

    get playerB()       {return this._playerB}        
    set playerB(value)  { this._playerB = value ? new Player(value): undefined;}

    //hardcode analog speedStep
    //time to step sets front, but speedStep gives back backend
    get gameSpeedTextId() {
        if (this.rules && this.rules.timeLimit && this.rules.timeLimit.step) {
            let step = this.rules.timeLimit.step;
            if (step > GAME_TIME_STEP_VARIANTS[2]) {
                return EGameSpeed.SLOW;
            }
            if (step > GAME_TIME_STEP_VARIANTS[0]) {
                return EGameSpeed.NORMAL;
            }
            return EGameSpeed.EXPRESS;
        }
        return undefined
    }

    get stepTime() {
        if (this.rules && this.rules.timeLimit && this.rules.timeLimit.step) {
            return this.rules.timeLimit.step
        }
        return 0;
    }

    isDead():boolean { 
        return this.status == EGameStatus.FINISHED || this.status == EGameStatus.CANCELED;
    }     

    isTypeTraining() {return this.type_id == EGameType.TRAIN;}
    isTypeMatch() {return this.type_id == EGameType.CUSTOM_MATCH;}
    isTypeTournament() {return this.type_id == EGameType.TOURNAMENT;}
    
    isPlayerIn(playerUuid:string):boolean {        
        if(this.playerA && this.playerA.uuid == playerUuid)
            return true;
        if(this.playerB && this.playerB.uuid == playerUuid)
            return true;
        return false;
    }

    isPlayerCreator(playerUuid:string):boolean {        
        if(this.playerA && this.playerA.uuid == playerUuid)
            return true;        
        return false;
    }

    isStatusJoinable():boolean {
        return this.status == EGameStatus.SEARCH_OPPONENT
    }   

    isStatusInPlay():boolean {
        return this.status == EGameStatus.IN_PLAY
    }    

    isStatusRevengeWaitPlayer():boolean {
        return this.status == EGameStatus.WAIT_PLAYERS
    }    

    getPlayerRole(uuid:string):string|null {
        if(this.playerA && this.playerA.uuid == uuid)
            return "playerA";

        if(this.playerB && this.playerB.uuid == uuid)
            return "playerB";

        return null
    }

    getWaitingTimeMax() { return this.timeout_timestamp - this.created_timestamp;}

    getWinnerRole():string|null {
        if(this.isScoreParitet())        
            return null;

        return (this.player_a_score > this.player_b_score) ? "playerA" : "playerB"        
    }

    getPlayerScore(uuid:string):number|null {
        let role = this.getPlayerRole(uuid);
        if(role == "playerA") 
            return this.player_a_score;
         
        if(role == "playerB") 
            return this.player_b_score;
        
        return null;
    }

    isScoreParitet():boolean {
        return this.player_b_score == this.player_a_score;
    }

}

// uuid: "41f4e623-0932-4cda-94fa-5d966e3152c8"
// player_a_score: 0
// player_b_score: 0
// player_a_uuid: "686cb095-1d67-4c25-aae3-5ec99e772d93"
// player_b_uuid: null
// bet_price: 10
// win_total: null
// caption: ""
// max_score: 1
// status: 10
// isInPlay: false
// isFinished: false
// speedStep: "express"
// isNeedPassword: false
// type_id: 2
// game_type_id: 2
// timeout: "2020-04-09 16:50:54+03"
// timeout_timestamp: 1586440254
// created: "2020-04-09 16:45:54+03"
// created_timestamp: 1586439954
// modified: "2020-04-09 16:45:54+03"
// modified_timestamp: 1586439954
// started: null
// started_timestamp: null
// finished: null
// finished_timestamp: null
// statistic: null
// statusString: "STATUS_SEARCH_OPPONENT"
// scoreString: "0:0"
// playerA: {avatarUri: "https://wbgdev.ngmcasino.com/nard_server/statics/pictures/avatars/avatar_15.png", countryCode: null, rating: 1469, isDemo: false, uuid: "686cb095-1d67-4c25-aae3-5ec99e772d93", …}
// playerB: null
// currency: {id: 10011, entity: "5starsgammon", name: "Credit", code_name: "Cr", minor_unit: 0, …}